import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
// import Accordion from "react-bootstrap/Accordion"

import Layout from "../../components/layout/basic"
import SEO from "../../components/global/SEO"
import FormConnect from "../../components/global/FormConnect"

const Locations = [
  { name: "будинка" },
  { name: "квартири" },
  { name: "під'їзду" },
  { name: "офісу" },
  { name: "кафе і ресторанів" },
  { name: "магазину і супермаркету" },
  { name: "вулиці і території" },
  { name: "підприємства або виробництва" },
  { name: "банку" },
  { name: "складу" },
  { name: "готелю" },
  { name: "торгівельного центру" },
]
// const cameraTypes = [
//   "Установка IP відеокамер",
//   "Установка HD відеокамер",
//   "Установка PTZ відеокамер",
//   "Установка вуличних відеокамер",
//   "Установка бездротових відеокамер",
//   "Установка відеокамер без об'єктива",
//   "Установка відеокамери з зумом",
//   "Установка поворотних відеокамер",
//   "Установка безкорпусних відеокамер",
//   "Установка нічних відеокамер",
//   "Установка мініатюрних відеокамер",
// ]
const videoSet = {
  title: "Відеоспостереження для будинка",
  priceDevices: 4500,
  priceInstall: 2150,
  priceOffer: 7650,
  termInstall: "1 день",
  warrantyDevices: "1 рік",
  warrantyWork: "1 рік",
  freeMaintenance: "3 місяці",
  devices: [
    { name: "camera 1", price: 1200, quantity: 1 },
    { name: "camera 2", price: 1200, quantity: 1 },
    { name: "cabels", price: 200, quantity: 1 },
    { name: "router", price: 900, quantity: 1 },
  ],
}
// const monitoringTypes = [{}]

export default function VideoPage() {
  const data = useStaticQuery(graphql`
    {
      bgImage: contentfulAsset(
        title: { eq: "lianhao-qu-LfaN1gswV5c-unsplash" }
        node_locale: { eq: "uk" }
      ) {
        title
        fluid(
          maxWidth: 1200
          maxHeight: 600
          resizingBehavior: FILL
          cropFocus: FACE
          quality: 100
        ) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      brands: allContentfulShopBrand(filter: { node_locale: { eq: "uk" } }) {
        nodes {
          id
          name
          logo {
            fluid(maxWidth: 350, quality: 85) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  `)

  const bgImage = [
    data.bgImage.fluid,
    `linear-gradient(to left,rgba(81, 45, 168, 0.3) 0,#f6f4fb 80%)`,
  ].reverse()

  return (
    <Layout>
      <SEO
        title="Відеоспостереження, Відеонагляд - Новий Телеком"
        description="Відеоспостереження під ключ від Інтернет-провайдера Newtelecom в Київської області і Києві - Новий Телеком."
        image=""
        url="/video/"
        lang="uk"
        noindex="noindex"
      />
      <BackgroundImage fluid={bgImage} className="hero-background-light">
        <Container className="py-5">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Newtelecom</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Відеоспостереження
              </li>
            </ol>
          </nav>

          <Row>
            <Col
              xs={12}
              md={8}
              className="d-flex flex-wrap align-content-center justify-content-start"
            >
              <h1 className="font-weight-bold">
                Встановлення{" "}
                <span className="gradient-text d-block">відеонагляду</span>
              </h1>
              <div className="hero-slogan py-4">
                Проектування, встановлення й&nbsp;обслуговування систем
                відеоспостереження в&nbsp;Києві та&nbsp;Київській області під
                ключ. Доступ 24/7 зі смартфону.
                <br />
                Налаштування систем пожежної безпеки та&nbsp;постановка на
                охорону.
              </div>
            </Col>
            <Col xs={12} md={4} className="bg-white pt-4">
              <div className="mx-4 mb-3 h4">Безкоштовна консультація</div>
              <FormConnect formType="video" />
            </Col>
          </Row>
        </Container>
      </BackgroundImage>

      <Container className="py-5">
        <h2>Переваги</h2>
        <ul>
          <li>Надійне обладнання, перевірене роками на власному досвіді.</li>
          <li>Кращі ціни від виробника на обладнання.</li>
          <li>
            Безперебійна експлуатація за будь-яких умов, попри сніг, дощ, вітер,
            навіть при перебоях в електромережі.
          </li>
          <li>
            Повний контроль з будь-якого місця, де є інтернет. Побачите все, що
            відбувається у вас на підприємстві в режимі онлайн на екрані вашого
            смартфону.
          </li>
          <li>
            Своя досвідчена команда монтажників, яка буде проводити планову
            перевірку роботи або модернізацію систем.
          </li>
        </ul>
      </Container>

      <Container className="py-5" fluid>
        <Container className="h2">Готові комплекти</Container>
        <Row className="mx-5 mt-5">
          {Locations.map(loc => (
            <Col key={loc.name} xs={12} md={6} lg={4} xl={3} className="mb-4">
              <Card
                border="light"
                className="newtele-shadow d-flex align-items-stretch h-100"
              >
                <Card.Img
                  as={Img}
                  variant="top"
                  fluid={data.bgImage.fluid}
                  className=""
                />

                <Card.Body className="d-flex align-content-between flex-wrap">
                  <Card.Title>{`Відеоспостереження для ${loc.name}`}</Card.Title>

                  <Card.Text as="div">
                    <p>Ціна пристроїв в наборі - {videoSet.priceDevices}</p>
                    <p>Ціна монтажу - {videoSet.priceInstall}</p>
                    <p>Термін встановлення - {videoSet.termInstall}</p>
                    <p>Гарантія на обладнання - {videoSet.warrantyDevices}</p>
                    <p>Гарантія на роботи - {videoSet.warrantyWork}</p>
                    <p>Безкоштовне обслуговання - {videoSet.freeMaintenance}</p>
                    <span>
                      Перелік пристроїв в наборі:{" "}
                      {videoSet.devices.map(device => (
                        <p
                          key={device.name}
                        >{`${device.name} - ${device.price}грн - ${device.quantity}`}</p>
                      ))}
                    </span>
                    <p>
                      Повна ціна:{" "}
                      {videoSet.priceDevices + videoSet.priceInstall}, ціна зі
                      знижкою: {videoSet.priceOffer}
                    </p>
                  </Card.Text>
                  <Button variant="light">Замовити комплект</Button>
                  <Button variant="link">Консультація</Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      <Container className="py-5">
        <Row className="">
          <Col sm={12} md={6} className="my-4">
            <div className="h3 mb-4">Безкоштовна консультація</div>
            <p>
              Телефонуйте, щоб дізнатися можливі строки інсталяції та попередню
              вартість проєкту віеоспостередження.
            </p>
            <p>
              Наші фахівці допоможуть підібрати оптимальне рішення під ваш
              об'єкт з урахуванням бюджету, вимог і можливостей обладнання.
            </p>
            <Button variant="light">Отримати консультацію</Button>
          </Col>
          <Col sm={12} md={6} className="my-4">
            <div className="h3 mb-4">Безкоштовний виїзд</div>
            <p>
              Щоб відеоспостереження не тягнуло зайвих витрат і повністю
              виконувало свої задачі, важливо почати саме з проєктування, а не з
              покупки устаткування з подальшими намаганнями його розміщення і
              застосування.
            </p>
            <p>
              Проектуємо під об'єкт з урахуванням особливостей локації доступу
              до електроживлення, облік мертвих зон, видимості в темряві і якщо
              потрібно - незалежність від погоди й перебоїв в електриці.
            </p>
            <Button variant="light">Замовити виїзд фахівця</Button>
          </Col>
          <Col sm={12} md={6} className="my-4">
            <div className="h3 mb-4">Гарантованний кошторис</div>
            <p>
              Отримуєте кошторис, що включає необхідне обладнання, перелік
              матеріалів для монтажу і список робіт з вартістю. Після
              передоплати за матеріали й обладнання призначаємо дату монтажу.
            </p>
          </Col>
          <Col sm={12} md={6} className="my-4">
            <div className="h3 mb-4">Монтаж і обслуговування</div>
            <p>
              Зазвичай всі роботи по монтажу та налагодженню виконуються
              протягом одного дня. Отримайте три місяці безплатного
              обслуговування і річну гарантію на обладнання.
            </p>
          </Col>
        </Row>
      </Container>

      {/* <div>
        <div>
          <h3>Private</h3>
          <div>
            <h4>Видеонаблюдение Для дома / Для дачи</h4>
            <ul>
              <li>Встановлення комплекту аналогової домофонії від: 1100₴</li>
              <li>Встановлення комплекту ip домофонії від: 1500₴</li>
              <li>Встановлення комплекту з 4-х вуличних камер від: 3800₴*</li>
              <li>Встановлення комплекту з 8-ми вуличних камер від: 5100₴*</li>
              <li>Встановлення сигналізації (базовий комплект) 960₴</li>
              <li>Встановлення периметральних датчиків (ІЧ бар'єр)</li>
            </ul>
          </div>
          <div>
            <h4>Видеонаблюдение В квартире</h4>
            <ul>
              <li>Встановлення ip камери перед дверима 1100₴</li>
              <li>Встановлення ip камери всередині квартири 1000₴</li>
              <li>Встановлення комплекту аналогової домофонії 1100₴</li>
              <li>Встановлення комплекту ip домофонії 1500₴</li>
              <li>Встановлення індивідуальної ip панелі 1080₴</li>
              <li>Встановлення сигналізації (базовий комплект) 960₴</li>
            </ul>
          </div>
        </div>
        <div>
          <h3>Business</h3>
          <ul>
            <li>Видеонаблюдение Офиса</li>
            <li>Видеонаблюдение Магазина</li>
            <li>Видеонаблюдение Кафе и ресторана</li>
            <li>Видеонаблюдение Территории, на улице</li>
            <li>Видеонаблюдение Склад</li>
            <li>Видеонаблюдение на производстве и предприятии</li>
          </ul>
          <h4>Prices</h4>
          <ul>
            <li>Встановлення комплекту аналогової домофонії від: 1100₴</li>
            <li>Встановлення комплекту ip домофонії від: 1500₴</li>
            <li>
              Встановлення доводчика і електромагнітного / електромеханічного
              замка від: 1500₴
            </li>
            <li>
              Встановлення мережевого контролера СКУД і внесення до 20 абонентів
              від: 1400₴*
            </li>
            <li>Встановлення сигналізації (базовий комплект) 960₴</li>
            <li></li>
            <li>Встановлення камери всередині приміщення 1080₴</li>
            <li>Встановлення камери на вулиці 1140₴</li>
            <li>Встановлення комплекту з 4-х камер від: 3800₴*</li>
            <li>Встановлення комплекту з 8-ми камер від: 5100₴*</li>
          </ul>
        </div>

        <div>Черези нтернет / Онлайн</div>
        <div>Під ключ</div>
      </div> */}
      <Container className="py-5">
        <h3>Обладнання</h3>
        <Row className="">
          {data.brands.nodes.map(brand => (
            <Col
              key={brand.id}
              className="align-self-center px-4"
              xs={6}
              sm={6}
              md={3}
            >
              <Img fluid={brand.logo.fluid} alt={brand.name} className="" />
            </Col>
          ))}
        </Row>
      </Container>
      <Container>
        <div>Охранные системы: пожарная сигнализация</div>
        <div>Галерея фотографій з об`єктів</div>
      </Container>
      <Container>
        <div>
          Готові рішення з відеоспостереження Система відеоспостереження
          недорого - дуже просто, використовуйте готові рішення
        </div>
        <div>
          Проектування системи відеоспостереження Складання проекту з
          урахуванням особливостей задачі і можливостей обладнання
        </div>

        <div>
          Установка системи відеоспостереження під ключ Налаштування систем
          відеоспостереження Прокладка кабельних трас, підключення та запуск
          системи
        </div>

        <div>
          Супровід працюючої системи Щотижнева перевірка коректності роботи
          системи
        </div>

        <div>
          Оновлення системи відеоспостереження Модернізація системи
          відеоспостереження, заміна камер і реєстраторів
        </div>
      </Container>
    </Layout>
  )
}
